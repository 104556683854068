//version 1.0
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import firebase from 'firebase'
import firebase from "firebase/app"
import 'firebase/firebase-messaging'
const configOptions = {
    apiKey: "AIzaSyAYGcKq7F4uZuctXJZKRg3R7lkPhTK0HW0",
    authDomain: "dealersgearcrm-website.firebaseapp.com",
    databaseURL: 'https://dealersgearcrm-website.firebaseio.com',
    projectId: "dealersgearcrm-website",
    storageBucket: "dealersgearcrm-website.appspot.com",
    messagingSenderId: "118905443850",
    appId: "1:118905443850:web:00b83c00465a00f62931f3",
    measurementId: "G-KW18CGMPL2"
};

firebase.initializeApp(configOptions);
// Set Default Api URl
import axios from 'axios'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

// Set BASE URL
var mixin = {
    data: function () {
        return {
            message: 'hello'
        }
    }
}

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)
import $ from 'jquery'
import 'popper.js'
import 'bootstrap'
//import 'bootstrap/scss/bootstrap'

$(document).ready(function () {
    $(document).click(function (e) {
        if (e.target.id == "bel" || e.target.id == "bel1" || e.target.id == "bel2") {
            $(".notif-tabs").toggleClass("opens");
        } else {
            $(".notif-tabs").removeClass("opens");
        }
    });
});

import './assets/css/style.scss'
import './assets/css/responsive.css'

import moment from 'moment'

// International Telephone
import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput)

// Confirm Dialogue
import VueConfirmDialog from 'vue-confirm-dialog'
Vue.use(VueConfirmDialog)
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
import movable from 'v-movable'
Vue.use(movable)
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);
// Form Validation
import Vue2Storage from 'vue2-storage'
Vue.use(Vue2Storage, {
    prefix: 'crm_',
    driver: 'local',
    ttl: 60 * 60 * 24 * 1000 // 24 hours
})

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import Echo from 'laravel-echo'
window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: "06286585db0d00714682",
    cluster: "us2",
    encrypted: true,
});

// import Echo from 'laravel-echo'
// window.Pusher = require('pusher-js');
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: "anykey",
//     cluster: "mt1",
//     encrypted: false,
//     wsHost: window.location.hostname,
//     wsPort: 6001,
//     forceTLS: false,
//     disableStats: true,
// });

// Socket connection
/*import VueSocketIO from 'vue-socket.io'
window.socketo = Vue.use(new VueSocketIO({
    debug: false,
    connection: 'https://dealersgearcrm.com:6002',
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
    options: { path: "/my-app/" } //Optional options
}));*/

import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)
import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)
import VueHtmlToPaper from 'vue-html-to-paper'
const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        //'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' 
    ]
}

Vue.use(VueHtmlToPaper, options);


// Vue Filters
Vue.filter('splitVehicleImage', function (value) {
    if (value != '' && value != undefined && value != null && value != 'undefined') {
        var imgs = value.split(",");
        return imgs[0]
    }
});
Vue.filter('hour', function (value) {
    var hour = '';
    if (value > 60) {
        hour = parseFloat(Math.floor(value / 60)).toFixed(2) + ' Hours';
    } else {
        hour = parseFloat(value).toFixed(2) + ' Minutes';
    }
    return hour;
});
Vue.filter('parseString', function (value) {
    var str = value.replace(/(<([^>]+)>)/gi, "")
    return str.substr(0, 80)
});
Vue.filter('formatDate', function (value, format = 'MM/DD/YYYY hh:mm') {
    if (value) {
        return moment(String(value)).format(format)
    }
});
Vue.filter('deskingdateformate', function (value, format = 'MM/DD/YYYY') {
    if (value) {
        return moment(String(value)).format(format)
    }
});
Vue.filter('formatenotidate', function (value, format = 'MMMM D YYYY, h:mm A') {
    if (value) {
        return moment(String(value)).format(format)
    }
});
Vue.filter('numberStep', function (value, limit) {
    var nu = '-' + limit;
    return ('000' + value).slice(parseInt(nu));
});
Vue.filter('GroupchatDate', function (value, format = 'MM/DD/YYYY hh:mm A') {
    if (value) {
        return moment(String(value)).format(format)
    }
});
Vue.filter('customMultiSelectPreview', function (value, column) {
    var text = '';
    value.forEach(function (element, index) {
        if (index > 0) text += ', '
        text += element[column];
    });
    return text;
});
//pluraize filter
Vue.filter('pluralize', (word, amount) => (amount > 1 || amount === 0) ? `${word}s` : word)
Vue.filter('customMultiSelectPreviewCheckbox', function (value, column) {
    var text = '';
    value.forEach(function (element, index) {
        if (index > 0) text += ', '
        text += element[column]; 
    });
    return text;
});
Vue.filter('roundoff', function (value) {

    if (value == 0) {
        return 0;
    } else {
        return value.toFixed(2);
    }
});
Vue.filter('substring', function (value) {
    var arr = value.split(',')
    return arr[0];
});
Vue.filter('time', function (value, format = 'hh:mm') {
    if (value) {
        return moment(String(value)).format(format)
    }
});
Vue.filter('timeDifference', function (updated, created) {

    var start_actual_time = new Date(created);
    var end_actual_time;
    if (updated == undefined || updated == null || updated == '' || updated == 'undefined') {
        end_actual_time = new Date();
    } else {
        end_actual_time = new Date(updated);
    }
    return Math.floor((end_actual_time - start_actual_time) / 1000);
    // if (diff > 0) {
    //   var d = diff/1000;
    //   var h = Math.floor(d / 3600);
    //   var m = Math.floor(d % 3600 / 60);
    //   var s = Math.floor(d % 3600 % 60);

    //   var hDisplay = h > 0 ? h + ':' : "";
    //   var mDisplay = m > 0 ? m + ':' : "";
    //   var sDisplay = s > 0 ? s : "";
    //   return hDisplay + mDisplay + sDisplay;
    // }else{
    //   return '00:00'
    // }
});

Vue.filter('str_limit', function (value, size) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
        return value;
    }
    return value.substr(0, size) + '...';
});

Vue.filter('removePlusSign', function (phone) {

    // var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    // var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    // if (match) {
    //   var intlCode = (match[1] ? '+1 ' : '')
    //   return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    // }
    // return null

    if (!phone) return '';
    if (phone.indexOf('+1') > -1) {
        var phoneNumberString = phone.replace('+1', '');
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return phone;
    } else {
        return phone;
    }
});

// Get Percentage
Vue.filter('getPercentage', function (obtained, total, customtoFix = 1) {
    if (obtained == undefined || obtained == 'undefined' || obtained == null || total == undefined || total == 'undefined' || total == null) {
        return 0;
    }
    if (parseInt(total) == 0) {
        return 0
    }
    var p = (parseInt(obtained) / parseInt(total)) * 100;

    if (p > 100) {
        return 100;
    } else {
        if (typeof p === 'number') {
            if (p % 1 === 0) {
                return p
            } else {
                return p.toFixed(customtoFix)
            }
        }

    }

});

Vue.filter('formatPrice', function (value) {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

});


Vue.config.productionTip = false
new Vue({
    mixins: [mixin],
    router,
    store,
    created: function () {
        this.globalReadOnlyProperty = "This won't change it";
    },
    render: h => h(App)
}).$mount('#app')