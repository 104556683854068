import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("../views/dashboard.vue"),
        meta: {
            pageTitle: "Manager Portal",
            agentPageTitle: "My Portal"
        }
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/login.vue"),
        meta: {
            title: "Login",
            metaTags: [
                {
                    name: "description",
                    content: "Login on "
                },
                {
                    property: "og:description",
                    content: "The home page of our example app."
                }
            ]
        }
    },
    {
        path: "/sessions/otp",
        name: "OTPLogin",
        component: () => import("../views/OTPLogin.vue"),
        meta: {
            title: "Two Factor Authentication",
            metaTags: [
                {
                    name: "description",
                    content: "Two Factor Authentication is enabled on your account."
                },
                {
                    property: "og:description",
                    content: "Two Factor Authentication is enabled on your account."
                }
            ]
        }
    },
    {
        path: "/sessions/error/deactivated",
        name: "DealerDeactivateError",
        component: () => import("../views/DealerDeactivateError.vue"),
        meta: {
            title: "Dealer Deactivate Error",
            metaTags: [
                {
                    name: "description",
                    content: "Dealer is not enabled on your account."
                },
                {
                    property: "og:description",
                    content: "Dealer is not enabled on your account."
                }
            ]
        }
    },
    {
        path: "/logout",
        name: "Logout",
        component: () => import("../views/logout.vue")
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/dashboard.vue"),
        meta: {
            pageTitle: "Manager Portal",
            agentPageTitle: "My Portal"
        }
    },
    {
        path: "/teams",
        name: "Teams",
        component: () => import("../views/teams.vue"),
        meta: {
            pageTitle: "Teams"
        }
    },
    {
        path: "/members/:slug",
        name: "Members",
        props: true,
        component: () => import("../views/teamMembers.vue"),
        meta: {
            pageTitle: "Team Members"
        }
    },
    {
        path: "/reports/:cate",
        name: "Reports",
        component: () => import("../views/reports.vue"),
        meta: {
            pageTitle: "Reports"
        }
    },
    {
        path: "/desking/:id",
        name: "Desking",
        component: () => import("../views/desking.vue"),
        meta: {
            pageTitle: "Desking"
        }
    },
    {
        path: "/leads",
        name: "Leads",
        component: () => import("../views/leads.vue"),
        meta: {
            pageTitle: "Leadsheets"
        }
    },
    {
        path: "/lead-detail/:id/:notification_type?/:notification_id?",
        name: "LeadDetail",
        component: () => import("../views/leadDetail.vue")
    },
    {
        path: "/schedule",
        name: "weeklySchedule",
        component: () => import("../views/weeklySchedule.vue"),
        meta: {
            pageTitle: "Working Hours"
        }
    },
    {
        path: "/campaigns-list",
        name: "campaignsList",
        component: () => import("../views/emailCampaignsList.vue"),
        meta: {
            pageTitle: "Campaigns List"
        }
    },
    {
        path: "/campaign-list-generator",
        name: "campaignListGenerator",
        component: () => import("../views/emailCampaignsListGenerator.vue"),
        meta: {
            pageTitle: "List Generator"
        }
    },
    {
        path: "/edit-campaign-list/:id",
        name: "editCampaignList",
        component: () => import("../views/editCampaignList.vue"),
        meta: {
            pageTitle: "Edit Campaign List"
        }
    },
    {
        path: "/campaigns",
        name: "emailCampaigns",
        component: () => import("../views/emailCampaigns.vue"),
        meta: {
            pageTitle: "Manage Campaigns"
        }
    },
	{
        path: "/services",
        name: "services",
        component: () => import("../views/services.vue"),
        meta: {
            pageTitle: "Services"
        }
    },
    {
        path: "/services-info",
        name: "servicesinfo",
        component: () => import("../views/servicesinfo.vue"),
        meta: {
            pageTitle: "Services Info"
        }
    },
    {
        path: "/services2",
        name: "services2",
        component: () => import("../views/services2.vue"),
        meta: {
            pageTitle: "Services Info"
        }
    },
    {
        path: "/add-new-campaign",
        name: "addNewEmailCampaigns",
        component: () => import("../views/addNewEmailCampaigns.vue"),
        meta: {
            pageTitle: "Add New Campaign"
        }
    },
    {
        path: "/customers",
        name: "customers",
        component: () => import("../views/customers.vue"),
        meta: {
            pageTitle: "Customers"
        }
    },
    {
        path: "/customer/detail/:id",
        name: "customerDetail",
        component: () => import("../views/customerDetail.vue"),
        meta: {
            pageTitle: "Customer Detail"
        }
    },
    {
        path: "/text-campaign",
        name: "Text Campaigns",
        component: () => import("../views/textCampaigns.vue"),
        meta: {
            pageTitle: "Text Campaigns"
        }
    },

    {
        path: "/add-new-text-campaign",
        name: "Add Text Campaigns",
        component: () => import("../views/addtextCampaigns.vue"),
        meta: {
            pageTitle: "Add Text Campaigns"
        }
    },
    {
        path: "/alerts",
        name: "alerts",
        component: () => import("../views/alerts.vue"),
        meta: {
            pageTitle: "Alerts"
        }
    },
    {
        path: "/appointments",
        name: "appointments",
        component: () => import("../views/allAppointments.vue"),
        meta: {
            pageTitle: "Appointments"
        }
    },
    {
        path: "/tasks",
        name: "tasks",
        component: () => import("../views/allTasks.vue"),
        meta: {
            pageTitle: "Tasks"
        }
    },
    {
        path: "/attribution",
        name: "attribution",
        component: () => import("../views/attribution.vue"),
        meta: {
            pageTitle: "Attribution"
        }
    },
    {
        path: "/inventory",
        name: "inventory",
        component: () => import("../views/inventory.vue"),
        meta: {
            pageTitle: "Inventory"
        }
    },
    {
        path: "/report-settings",
        name: "reportSettings",
        component: () => import("../views/reportSettings.vue"),
        meta: {
            pageTitle: "Report Settings"
        }
    },
    {
        path: "/my-reports",
        name: "myReports",
        component: () => import("../views/myReports.vue"),
        meta: {
            pageTitle: "My Reports"
        }
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import("../views/settings.vue"),
        meta: {
            pageTitle: "Settings"
        }
    },
    {
        path: "/templates",
        name: "templates",
        component: () => import("../views/template.vue"),
        meta: {
            pageTitle: "Email Templates"
        }
    },
    {
        path: "/add-template",
        name: "addTemplate",
        component: () => import("../views/addTemplate.vue"),
        meta: {
            pageTitle: "Add Email Template"
        }
    },
    {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/editProfile.vue"),
        meta: {
            pageTitle: "Edit Profile"
        }
    },
    //admin routes
    {
        path: "/admin/dealers",
        name: "Dealers",
        component: () => import("../views/admin/dealers.vue"),
        meta: {
            pageTitle: "Dealers"
        }
    },
    {
        path: "/admin/managers/:id",
        name: "Managers",
        component: () => import("../views/admin/managers.vue"),
        meta: {
            pageTitle: "Managers"
        }
    },
    {
        path: "/add-dealer",
        name: "addDealer",
        component: () => import("../views/admin/addNewDealer.vue"),
        meta: {
            pageTitle: "Add New Dealer"
        }
    },
    {
        path: "/admin/edit-dealer/:id",
        name: "editDealer",
        component: () => import("../views/admin/edit_dealer.vue"),
        meta: {
            pageTitle: "Edit Dealer"
        }
    },
    {
        path: "/forgot",
        name: "forgot",
        component: () => import("../views/forgot.vue")
    },
    {
        path: "/reset-password",
        name: "reset",
        component: () => import("../views/forgotpassword.vue")
    },

    {
        path: "/videos",
        name: "videos",
        component: () => import("../views/video.vue"),
        meta: {
            pageTitle: "Videos"
        }
    },
    {
        path: "/message",
        name: "message",
        component: () => import("../views/chat.vue"),
        meta: {
            pageTitle: "Messages"
        }
    },
    {
        path: "/help",
        name: "Help",
        component: () => import("../views/help.vue"),
        meta: {
            pageTitle: "Helps"
        }
    },
    {
        path: "/dms",
        name: "DMS",
        component: () => import("../views/dms.vue"),
        meta: {
            pageTitle: "DMS"
        }
    },

    {
        path: "/raw-leads",
        name: "RAWLEADS",
        component: () => import("../views/RawLeads.vue"),
        meta: {
            pageTitle: "Raw Leads"
        }
    },
    {
        path: "/print",
        name: "CampaignStats",
        component: () => import("../views/print.vue"),
        meta: {
            pageTitle: "Campaign Stats"
        }
    },


    {
        path: "/login-logs",
        name: "LOGINLOGS",
        component: () => import("../views/LoginLogs.vue"),
        meta: {
            pageTitle: "Login Logs"
        }
    },
    {
        path: "/delete-lead-logs",
        name: "DeleteleadLogs",
        component: () => import("../views/deleteleadlogs.vue"),
        meta: {
            pageTitle: "Delete Lead Logs"
        }
    },
    {
        path: "/desking-log",
        name: "Desking Log",
        component: () => import("../views/Deskinglog.vue"),
        meta: {
            pageTitle: "Desking Log"
        }
    },
	{
        path: "/notifications",
        name: "notifications",
        component: () => import("../views/manageNotification.vue"),
        meta: {
            pageTitle: "Manage Notifications"
        }
    },
    {
        path: "/desking-setting",
        name: "Desking Setting",
        component: () => import("../views/Deskingsetting.vue"),
        meta: {
            pageTitle: "Desking Setting"
        }
    },
    {
        path: "/chat",
        name: "Chat",
        component: () => import("../views/message.vue"),
        meta: {
            pageTitle: "Inner Circle"
        }
    },
    {
        path: "/sms-templates",
        name: "Sms Templates",
        component: () => import("../views/sms-template.vue"),
        meta: {
            pageTitle: "Text Templates"
        }
    },

    {
        path: "/add-smstemplate",
        name: "addTemplate",
        component: () => import("../views/addSmsTemplate.vue"),
        meta: {
            pageTitle: "Sms Template"
        }
    },
    {
        path: "/call-history-logs",
        name: "callHistory",
        component: () => import("../views/callHistory.vue"),
        meta: {
            pageTitle: "Call History"
        }
    },
    {
        path: "/sms-tracking-logs",
        name: "smsHistory",
        component: () => import("../views/smsHistory.vue"),
        meta: {
            pageTitle: "Sms History"
        }
    },
    {
        path: "/call-settings",
        name: "callSettings",
        component: () => import("../views/callSetting.vue"),
        meta: {
            pageTitle: "Call Setting"
        }
    },
    {
        path: "/campaigns/automation/:id",
        name: "CampaignStats",
        component: () => import("../views/automationCampaign.vue"),
        meta: {
            pageTitle: "Campaign Stats"
        }
    },

    // {
    //   path: '/',
    //   redirect: '/dashboard',
    //   name: 'Home',
    //   component: TheContainer,
    //   children: [
    //     {
    //       path: 'dashboard',
    //       name: 'Dashboard',
    //       component: () => import('../views/dashboard.vue')
    //     },
    //     {
    //       path: 'team',
    //       meta: {
    //         label: 'Team'
    //       },
    //       name:'Team',
    //       component: () => import('../views/team.vue')
    //     }
    //   ]
    // },
];

const router = new VueRouter({
    mode: "history",
    base: "/",
    //base: process.env.BASE_URL,
    routes
});

export default router;
